








const coordsArr = [
    { top: "-42%", left: "-37%", width: 120, transform: -15 },
    { top: "59%", left: "31%", width: 120, transform: 180 },
    { top: "42%", left: "33%", width: 120, transform: -125 },
    { top: "36%", left: "44%", width: 120, transform: -69 },
    { top: "51%", left: "52%", width: 120, transform: -15 },
    { top: "65%", left: "57%", width: 120, transform: 15 },
    { top: "82%", left: "79%", width: 120, transform: 8 },
    { top: "73%", left: "34%", width: 120, transform: 135 },
    { top: "72%", left: "48%", width: 120, transform: 45 },
    { top: "57%", left: "37%", width: 120, transform: -15 },
    { top: "58%", left: "65%", width: 120, transform: -15},
    { top: "47%", left: "38%", width: 120, transform: -155 },
    { top: "-42%", left: "37%", width: 120, transform: -15 },
    { top: "-42%", left: "37%", width: 120, transform: -15 },
    { top: "70%", left: "27%", width: 120, transform: 155 },
    { top: "40%", left: "23%", width: 120, transform: -153 },
    { top: "-42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "57%", width: 120, transform: 25 },
    { top: "-42%", left: "-37%", width: 120, transform: -15 },
    { top: "-42%", left: "-37%", width: 120, transform: -15 },
    { top: "-42%", left: "-37%", width: 120, transform: -15 },
    { top: "66%", left: "41%", width: 120, transform: 90 },
    { top: "62%", left: "69%", width: 120, transform: -90 },
    { top: "66%", left: "78%", width: 120, transform: -40 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
    { top: "43%", left: "52%", width: 120, transform: -45 },
    { top: "42%", left: "-37%", width: 120, transform: -15 },
]

export default coordsArr